<script>
import { mapState } from 'vuex'

import SelectOrg from '@components/select/SelectOrg.vue'
import SelectJob from '@components/select/SelectJob.vue'
import RuleContent, { ruleType1, ruleType2 } from './components/RuleContent.vue'
import UploadFile from '@components/upload/uploadFile.vue'

import { getIntegralRule, createIntegralRule } from './api'


const ruleLimit = [
  { label: '不限', value: 'unlimited' },
  { label: '每天', value: 'every_day' },
  { label: '每周', value: 'every_week' },
  { label: '每月', value: 'every_monthly' },
  { label: '每年', value: 'every_year' },
  { label: '总计', value: 'all_total' }
]

export default {
  name: 'IntegralRuleDetail',
  components: { RuleContent, SelectJob, SelectOrg, UploadFile },
  data() {
    return {
      loading: false,

      ruleType1,
      ruleType2,
      ruleLimit,

      formState: {
        name: '',
        rule_type: undefined,
        rule_content_type: '1',
        rule_content: '',
        ruleContent: [], // for local display, should be converted into rule_content

        restrict: 'unlimited',
        integral_upper_line: 1,

        rule_description: '',
        orgids: undefined,
        postids: undefined,
        userids: undefined,

        time: [undefined, undefined], // for local display, should be converted into start_date and end_date
        start_date: '',
        end_date: ''
      }


    }
  },
  computed: {
    ...mapState({
      userTemplate: (state) => state.Offline.templates?.offline_import_push_user_template?.url || ''
    }),
    ruleContentTypeList() {
      const ruleType = this.ruleType2.find(i => i.id === this.formState.rule_type)
      return ruleType?.ruleTypeList || []
    },
    rules() {
      return {
        name: [{ required: true, message: '请输入规则名称' }],
        rule_type: [{ required: true, message: '请选择规则行为' }],
        rule_content_type: [{ required: !!this.ruleContentTypeList?.length, message: '请选择规则行为' }],
        ruleContent: [{
          required: !this.ruleContentTypeList?.length,
          validator: function(rule, value, callback) {
            if(!value?.length) {
              callback(new Error('请至少填写一个规则'))
            } else if(value.some(i => !i.count || !i.score)) {
              callback(new Error('规则填写不完全'))
            } else if(value.length !== Array.from(new Set(value.map(i => i.count))).length) {
              callback(new Error('次数不允许相同 '))
            } else {
              callback()
            }
          }
        }],

        restrict: [{ required: true, message: '请选择规则限制' }],
        integral_upper_line: [{ required: true, message: '请填写积分上限' }],

        rule_description: [{ required: true, message: '请输入规则说明' }],

        orgids: [{ required: false, message: '请选择推送机构' }],
        postids: [{ required: false, message: '请选择岗位' }],
        userids: [{ required: false, message: '请上传推送名单' }],

        start_date: [{ required: false, message: '请选择开始时间' }],
        end_date: [{ required: false, message: '请选择结束时间' }]
      }
    }
  },
  created() {
    this.$store.dispatch('Offline/getOfflineTemplate')

    this.getDetail()
  },
  methods: {
    async getDetail() {
      if(this.loading || !this.$route.query.id) {
        return
      }
      this.loading = true

      const params = { id: this.$route.query.id || '' }
      const data = await getIntegralRule(params).finally(() => {
        this.loading = false
      })
      if(!data || data.error_code) {
        return
      }

      const result = data.data || {}
      Object.keys(this.formState).forEach(key => {
        if(key !== 'ruleContent') {
          this.formState[key] = result[key]
        }
      })
      const ruleContentObj = JSON.parse(this.formState.rule_content || '{}')
      const ruleContent = []
      Object.keys(ruleContentObj).forEach(key => {
        ruleContent.push({ count: key, score: ruleContentObj[key] })
      })
      this.formState.ruleContent = ruleContent
      this.formState.time = [
        this.formState.start_date || undefined,
        this.formState.end_date || undefined
      ]
    },

    onRestrictChange() {
      if(this.formState.restrict === 'unlimited') {
        this.formState.integral_upper_line = 0
      } else {
        this.formState.integral_upper_line = 1
      }
    },

    onRuleTypeChange() {
      const ruleType = [...this.ruleType1, ...this.ruleType2].find(i => i.id === this.formState.rule_type)
      this.formState.rule_content_type = ruleType?.ruleTypeList?.[0]?.id
      this.formState.ruleContent = [{ count: this.formState.rule_content_type === 2 ? undefined : 1, score: 1 }]
    },
    onRuleContentTypeChange() {
      this.formState.ruleContent = [{ count: this.formState.rule_content_type === 2 ? undefined : 1, score: 1 }]
    },

    uploadNameListSuccess(data) {
      this.formState.userids = data.list.toString()
    },
    uploadLoading(value) {
      this.loading = value
    },

    onSubmit() {
      this.$refs.ruleForm.validate((isValid) => {
        if(isValid) {
          this.onSave()
        }
      })
    },
    async onSave() {
      if(this.loading) {
        return
      }
      this.loading = true

      const params = { ...this.formState }
      params.rule_content = {}
      params.ruleContent.forEach(i => {
        params.rule_content[i.count] = i.score
      })
      params.rule_content = JSON.stringify(params.rule_content || {})
      delete params.ruleContent
      params.start_date = params.time?.[0]?.format('YYYY-MM-DD HH:mm:ss') || ''
      params.end_date = params.time?.[1]?.format('YYYY-MM-DD HH:mm:ss') || ''
      delete params.time

      if(this.$route.query.id) {
        params.id = this.$route.query.id
      }

      const data = await createIntegralRule(params).catch(e => e)

      this.loading = false

      if(data?.error_code) {
        this.$message.error(data?.message || '保存失败')
        return
      }
      this.$message.success(data?.message || '保存成功')
      this.$router.back()
    }
  }
}
</script>

<template>
  <a-form-model ref="ruleForm"
                :model="formState"
                :rules="rules"
                :loading="loading"
                name="integralRule"
                layout="horizontal"
                autocomplete="off">
    <a-spin :spinning="loading" size="large" tip="加载中...">
      <a-form-model-item label="规则名称" name="name" prop="name">
        <a-input v-model="formState.name" placeholder="请填写规则名称" />
      </a-form-model-item>

      <a-form-model-item label="规则行为" name="rule_type" prop="rule_type">
        <a-radio-group v-model="formState.rule_type" @change="onRuleTypeChange">
          <a-radio v-for="item in ruleType1" :key="item.id" :value="item.id" class="mt-10 mb-10">
            {{ item.name }}
          </a-radio>
          <div style="width: 100%; height: 0;" />
          <a-radio v-for="item in ruleType2" :key="item.id" :value="item.id" class="mt-10 mb-10">
            {{ item.name }}
          </a-radio>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item v-show="formState.rule_type && ruleContentTypeList.length" label="规则" name="rule_content_type" prop="rule_content_type">
        <a-radio-group v-model="formState.rule_content_type" @change="onRuleContentTypeChange">
          <a-radio v-for="item in ruleContentTypeList" :key="item.id" :value="item.id" class="mt-10 mb-10">
            {{ item.label }}
          </a-radio>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item v-show="formState.rule_type"
                         :colon="!ruleContentTypeList.length"
                         :label="!ruleContentTypeList.length ? '规则' : ' '"
                         name="ruleContent"
                         prop="ruleContent">
        <RuleContent v-model="formState.ruleContent" :rule-type="formState.rule_type" :rule-content-type="formState.rule_content_type" />
      </a-form-model-item>

      <a-form-model-item label="限制" name="restrict" prop="restrict">
        <a-radio-group v-model="formState.restrict" @change="onRestrictChange">
          <a-radio v-for="item in ruleLimit" :key="item.value" :value="item.value" class="mt-10 mb-10">
            {{ item.label }}
          </a-radio>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item v-show="formState.restrict !== 'unlimited'"
                         :colon="false"
                         label=" "
                         name="integral_upper_line"
                         prop="integral_upper_line"
                         class="hide-required-symbol">
        <div class="flex flex-start">
          <span class="flex-none mr-10">积分上限</span>
          <a-input-number v-model="formState.integral_upper_line" :min="1" placeholder="请填写积分上限" style="width: 160px;" />
          <span class="ml-10">积分</span>
        </div>
      </a-form-model-item>

      <a-form-model-item label="规则说明" name="rule_description" prop="rule_description">
        <a-input v-model="formState.rule_description" type="textarea" no-resize :rows="4" placeholder="请填写规则说明" />
      </a-form-model-item>

      <a-form-model-item label="推送机构" name="orgids" class="fix-label-1">
        <SelectOrg v-model="formState.orgids" />
      </a-form-model-item>
      <a-form-model-item label="推送岗位" name="postids" class="fix-label">
        <SelectJob v-model="formState.postids" />
      </a-form-model-item>

      <a-form-model-item label="添加推送名单" name="userids">
        <UploadFile uploadAction="uploadNameList"
                    @success="uploadNameListSuccess"
                    @uploadLoainng="uploadLoading" />
        <span class="color-blue" v-if="formState.userids?.length > 0">已添加推送名单，再次上传将会覆盖原有名单！</span>

        <div>
          <a :href="userTemplate + '?title=推送名单模版.xlsx'" target="_blank">下载模版</a>
        </div>
      </a-form-model-item>

      <a-form-model-item label="时间" name="start_date">
        <a-range-picker v-model="formState.time" show-time />
      </a-form-model-item>
    </a-spin>

    <a-form-model-item label=" " :colon="false">
      <a-button :loading="loading" class="save-button" @click="onSubmit">保存</a-button>
    </a-form-model-item>
  </a-form-model>
</template>

<style scoped lang="scss">
.ant-form-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &.hide-required-symbol {
    ::v-deep .ant-form-item-required::before {
      display: none;
    }
  }

  &.fix-label-1 ::v-deep .ant-form-item-label {
    padding-bottom: 2px;
  }

  &.fix-label ::v-deep .ant-form-item-label {
    padding-bottom: 15px;
  }

  ::v-deep .ant-form-item-label {
    align-self: flex-start;
    width: 200px;
    padding-right: 10px;
    text-align: right;

    label {
      font-size: 14px;
      font-weight: 500;
      color: #262626;
    }
  }

  ::v-deep .ant-form-item-control-wrapper {
    flex: 1 1 calc(100% - 200px);
  }
}
</style>
