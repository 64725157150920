import request from '@/utils/Request';

// 基础信息——查看
export function baseInfoDetail(params) {
  return request({
    url: '/offline/admin/training/bases',
    method: 'get',
    params
  });
}

// 基础信息——新增
export function baseInfoAdd(data) {
  return request({
    url: '/offline/admin/training/bases/add',
    method: 'post',
    data
  });
}

// 基础信息——编辑
export function baseInfoEdit(data) {
  return request({
    url: '/offline/admin/training/bases/edit',
    method: 'post',
    data
  });
}

// 基础信息——删除
export function baseInfoDelete(data) {
  return request({
    url: '/offline/admin/training/bases/delete',
    method: 'post',
    data
  });
}

// 基础信息——上传推送名单
export function uploadNameList(data) {
  return request({
    url: '/offline/admin/training/bases/import',
    method: 'post',
    data
  });
}

// 基础信息——上传推送名单
export function commonUpload(data) {
  return request({
    url: '/public/conf/upload',
    method: 'post',
    data
  });
}

// 基础信息——本部审批人
export function getApprover() {
  return request({
    url: '/manage/admin/manage/managementList',
    method: 'get'
  });
}

// 页面信息配置-新增/编辑
export function infoPageEdit(data) {
  return request({
    url: '/offline/admin/training/info-page',
    method: 'post',
    data
  });
}

// 页面信息配置-查询
export function getInfoPage(training_id) {
  return request({
    url: '/offline/admin/training/info-page?training_id=' + training_id,
    method: 'get'
  });
}

// 页面信息配置-获取业务分类下拉列表
export function getCategoriesSelectList(params) {
  return request({
    url: '/offline/admin/training/categories/select-list',
    method: 'get',
    params
  });
}

// 报名配置-新增/编辑
export function signUpEdit(data) {
  return request({
    url: '/offline/admin/training/enroll',
    method: 'post',
    data
  });
}

// 报名配置-查询
export function getSignUp(training_id) {
  return request({
    url: '/offline/admin/training/enroll?training_id=' + training_id,
    method: 'get'
  });
}

// 报名配置-下拉查询
export function getSignUpSelectList(params) {
  return request({
    url: '/offline/admin/forms/select-list',
    method: 'get',
    params
  });
}

// 预习内容配置-列表
export function previewContentsList(data) {
  return request({
    url: '/offline/admin/training/preview-contents?training_id='+data,
    method: 'get'
  });
}

// 预习内容配置-下拉列表
export function previewContentsSelectList(params) {
  return request({
    url: '/offline/admin/training/preview-contents/select-list',
    method: 'get',
    params
  });
}

// 预习内容配置-删除
export function delPreviewContents(data) {
  return request({
    url: '/offline/admin/training/preview-contents/delete',
    method: 'post',
    data
  });
}

// 预习内容配置-新增
export function addPreviewContents(data) {
  return request({
    url: '/offline/admin/training/preview-contents/add',
    method: 'post',
    data
  });
}

// 证书模板-下拉接口查询
// 参数：cert_type 证书类型[1:课程2:任务3:培训]
export function certTemplate(data) {
  return request({
    url: `/public/conf/cert/template?cert_type=${data}`,
    method: 'get'
  });
}

// 证书领域类型-下拉接口查询
export function certDomaincate() {
  return request({
    url: '/public/conf/cert/domaincate',
    method: 'get'
  });
}

// 直播配置-列表
export function getLiveList(data) {
  return request({
    url: '/offline/admin/training/live/list'+data,
    method: 'get'
  });
}

// 直播配置-列表
export function liveClassAdd(data) {
  return request({
    url: '/offline/admin/training/live/add',
    method: 'post',
    data
  });
}

// 直播配置-列表
export function liveClassDel(data) {
  return request({
    url: '/offline/admin/training/live/del',
    method: 'delete',
    data
  });
}

// 模糊查询直播课-列表
export function getCourseList(params) {
  return request({
    url: '/offline/admin/training/courselist',
    method: 'get',
    params
  });
}

// 培训资料上传-列表
export function getFileList(data) {
  return request({
    url: '/offline/admin/training/file/list'+data,
    method: 'get',
    // data
  });
}

// 培训资料上传-新增
export function fileListAdd(data) {
  return request({
    url: '/offline/admin/training/file/add',
    method: 'post',
    data
  });
}

// 培训资料上传-修改
export function fileListModify(data) {
  return request({
    url: '/offline/admin/training/file/save',
    method: 'post',
    data
  });
}

// 培训资料上传-删除
export function fileListDel(data) {
  return request({
    url: '/offline/admin/training/file/del',
    method: 'delete',
    data
  });
}
//培训资料上传-详情
export function fileListshow(params) {
  return request({
    url: '/offline/admin/training/file/show',
    method: 'get',
    params
  });
}

// 讲师列表(下拉列表)
export function getLecturerSelectList(data) {
  return request({
    url: '/offline/admin/teachers/select-list?training_id=' + data,
    method: 'get',
  });
}

// 讲师列表 —— 新增保存
export function addTeacher(data) {
  return request({
    url: '/offline/admin/teachers/add',
    method: 'post',
    data
  });
}

// 讲师列表 —— 编辑保存
export function editTeacher(data) {
  return request({
    url: '/offline/admin/teachers/edit',
    method: 'post',
    data
  });
}

// 讲师列表 —— 查看详情
export function getTeacherDetail(data) {
  return request({
    url: '/offline/admin/teachers/show?id=' + data,
    method: 'get'
  });
}

// 班次配置——查询列表
export function getClassList(params) {
  return request({
    url: '/offline/admin/training/class',
    method: 'get',
    params
  });
}

//查看班次配置-调整班次
export function getClassConfig(params) {
  return request({
    url: '/offline/admin/training/class/config',
    method: 'get',
    params
  });
}
//保存班次配置-调整班次
export function setClassConfig(data) {
  return request({
    url: '/offline/admin/training/class/config',
    method: 'post',
    data
  });
}
// 班次配置——查询详情
export function getClassDetail(data) {
  return request({
    url: '/offline/admin/training/class/show?id=' + data,
    method: 'get'
  });
}

// 班次配置——查询班次下拉列表
export function getClassSelectList(params) {
  return request({
    url: '/offline/admin/training/class/select-list',
    method: 'get',
    params
  });
}

// 班次配置——保存详情
export function saveClassDetail(data) {
  return request({
    url: '/offline/admin/training/class',
    method: 'post',
    data
  });
}

// 班次配置——删除班次
export function deleteClass(data) {
  return request({
    url: '/offline/admin/training/class/delete',
    method: 'post',
    data
  });
}

// 班次配置——停止/恢复班次报名
export function stopResumeSign(data) {
  return request({
    url: '/offline/admin/training/class/stop-resume',
    method: 'post',
    data
  });
}

// 考勤配置——查询
export function getCheckWorkDetail(data) {
  return request({
    url: '/offline/admin/training/clocks?training_id=' + data,
    method: 'get'
  });
}

// 考勤配置——查询
export function saveCheckWork(data) {
  return request({
    url: '/offline/admin/training/clocks',
    method: 'post',
    data
  });
}

// 考试配置——试卷列表
export function getTestPaperList() {
  return request({
    url: '/offline/admin/training/test/testpaper',
    method: 'get'
  });
}

// 考试配置——班次列表
export function getClassesSelectList(data) {
  return request({
    url: '/offline/admin/training/classlist?training_id=' + data,
    method: 'get'
  });
}

// 调研配置——列表查询
export function getSurveyList(params) {
  return request({
    url: '/offline/admin/training/survey',
    method: 'get',
    params
  });
}

// 调研配置——删除
export function deleteSurveyList(data) {
  return request({
    url: '/offline/admin/training/survey/delete',
    method: 'post',
    data
  });
}

// 调研配置——详情
export function surveyDetail(data) {
  return request({
    url: '/offline/admin/training/survey/show?id=' + data,
    method: 'get'
  });
}

// 调研配置——新增
export function addSurvey(data) {
  return request({
    url: '/offline/admin/training/survey',
    method: 'post',
    data
  });
}

// 结业条件配置——详情
export function getConditionsDetail(data) {
  return request({
    url: '/offline/admin/training/conditions?training_id=' + data,
    method: 'get'
  });
}

// 调研配置——新增
export function addConditions(data) {
  return request({
    url: '/offline/admin/training/conditions',
    method: 'post',
    data
  });
}

// 提交材料配置——查询列表
export function getMaterialsList(data) {
  return request({
    url: '/offline/admin/training/materials'+data,
    method: 'get'
  });
}

// 提交材料配置——查询详情
export function getMaterialsDetail(data) {
  return request({
    url: '/offline/admin/training/materials/show?id='+data,
    method: 'get'
  });
}

// 提交材料配置——保存新增
export function saveMaterials(data) {
  return request({
    url: '/offline/admin/training/materials',
    method: 'post',
    data,
  });
}

// 提交材料配置——删除回传项
export function delMaterial(data) {
  return request({
    url: '/offline/admin/training/materials/delete',
    method: 'post',
    data
  });
}
