<script>
import SelectWithPager from '@components/select/SelectWithPager.vue'

export const ruleType1 = [
  {
    'id': 1,
    'name': '头像',
    ruleLabel: '更换头像'
  },
  {
    'id': 2,
    'name': '个性签名',
    ruleLabel: '更换个性签名'
  },
  {
    'id': 4,
    'name': '签到打卡',
    ruleLabel: '签到打卡'
  },
  {
    'id': 5,
    'name': '评论',
    ruleLabel: '评论'
  },
  {
    'id': 6,
    'name': '点赞',
    ruleLabel: '点赞'
  },
  {
    'id': 7,
    'name': '关注',
    ruleLabel: '关注'
  },
  {
    'id': 8,
    'name': '收藏',
    ruleLabel: '收藏'
  }
]
export const ruleType2 = [
  {
    'id': 3,
    'name': '发布内容',
    ruleLabel: '发布内容',
    ruleTypeList: [
      { id: 1, label: '发布次数', multiple: true },
      { id: 2, label: '参与话题', url: `/integral/admin/rule/find/topics`, options: { id: 'id', name: 'title' }, multiple: false }
    ]
  },
  {
    'id': 9,
    'name': '完成培训',
    ruleLabel: '完成培训',
    ruleTypeList: [
      { id: 1, label: '完训次数', multiple: true },
      { id: 2, label: '完训内容', url: `/integral/admin/rule/offlines`, options: { id: 'id', name: 'title' }, multiple: false }
    ]
  },
  {
    'id': 10,
    'name': '通过考试',
    ruleLabel: '通过考试',
    ruleTypeList: [
      { id: 1, label: '通过次数', multiple: true },
      { id: 2, label: '考试内容', url: `/integral/admin/rule/testpapers`, options: { id: 'id', name: 'name' }, multiple: false }
    ]
  },
  {
    'id': 11,
    'name': '完成任务',
    ruleLabel: '完成任务',
    ruleTypeList: [
      { id: 1, label: '通过次数', multiple: true },
      { id: 2, label: '任务内容', url: `/integral/admin/rule/learnplans`, options: { id: 'id', name: 'title' } }
    ]
  }
]

const ruleType1Ids = ruleType1.map(i => i.id)
const ruleType2Ids = ruleType2.map(i => i.id)

export default {
  name: 'RuleType',
  components: { SelectWithPager },
  props: {
    value: { type: Array, default: () => [] },
    ruleType: { type: [Number, String] },
    ruleContentType: { type: [Number, String] },

    disabled: { type: Boolean, default: false }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  computed: {
    ruleTypeItem() {
      return [...this.ruleType1, ...this.ruleType2].find(i => i.id === this.ruleType)
    },
    allowAddItem() {
      const ruleType = ruleType2.find(i => i.id === this.ruleType)
      if(!ruleType) {
        return true
      }
      const ruleTypeList = ruleType.ruleTypeList || []
      const ruleContentType = ruleTypeList.find(i => i.id === this.ruleContentType)
      if(!ruleContentType) {
        return true
      }
      return !!ruleContentType.multiple
    }
  },
  watch: {
    value: {
      handler(val) {
        if(!val?.length) {
          this.content = [{ count: this.ruleContentType === 2 ? undefined : 1, score: 1 }]
          return
        }

        this.content = val
      },
      immediate: true,
      deep: true
    },
    content: {
      handler() {
        this.$emit('change', this.content)
      },
      deep: true
    }
  },
  data() {
    return {
      ruleType1,
      ruleType2,
      ruleType1Ids,
      ruleType2Ids,

      content: []
    }
  },
  methods: {
    positiveIntegerFormatter(value) {
      if(value === undefined || value === null) {
        return undefined
      }
      return (value + '').replace('.', '') || undefined
    },
    positiveIntegerParser(value) {
      if(value === undefined || value === null) {
        return undefined
      }
      return +value.replace(/[^0-9]/g, '') || undefined
    },

    addItem(index) {
      this.content.splice(index + 1, 0, { count: this.ruleContentType === 2 ? undefined : 1, score: 1 })
    },
    removeItem(index) {
      this.content.splice(index, 1)
    }
  }
}
</script>

<template>
  <div>

    <div v-if="ruleType" class="rule-type-count">
      <div v-for="(item, index) in content" :key="index" class="item">
        <span>{{ ruleTypeItem?.ruleLabel }}</span>
        <template v-if="ruleContentType && ruleContentType === ruleTypeItem?.ruleTypeList?.[1]?.id">
          <SelectWithPager v-model="item.count"
                           mode="default"
                           :options="ruleTypeItem.ruleTypeList[1].options"
                           :api="ruleTypeItem.ruleTypeList[1].url"
                           :allow-select-all="false"
                           :use-local-pager="false"
                           class="pager-select" />
        </template>
        <template v-else>
          <a-input-number v-model="item.count"
                          :min="1"
                          :precision="0"
                          :formatter="positiveIntegerFormatter"
                          :parser="positiveIntegerParser"
                          placeholder="请输入次数"
                          class="input-number" />
          <span class="mr-20">次</span>
        </template>

        <span>可获得</span>
        <a-input-number v-model="item.score"
                        :min="1"
                        :precision="0"
                        :formatter="positiveIntegerFormatter"
                        :parser="positiveIntegerParser"
                        placeholder="请输入积分"
                        class="input-number" />
        <span class="mr-20">积分</span>

        <template v-if="allowAddItem">
          <a-icon type="plus-circle" class="plus-circle-icon" @click="addItem(index)" />
          <a-icon v-if="content.length > 1" type="minus-circle" class="minus-circle-icon" @click="removeItem(index)" />
        </template>
      </div>
    </div>

  </div>
</template>

<style scoped lang="scss">
.pager-select {
  display: inline-block;
  width: calc(100% - 350px);
  min-width: 380px;
  max-width: 510px;
  margin-left: 14px;
  margin-right: 20px;
}

.input-number {
  width: 100px;
  margin-left: 14px;
  margin-right: 14px;
}

.plus-circle-icon, .minus-circle-icon {
  width: 25px;
  height: 25px;
  margin-right: 7px;
  padding: 5px;
}
</style>
