<template>
  <a-upload
    v-if="isImgUpload"
    :multiple="multiple"
    :accept="accepts"
    list-type="picture-card"
    :disabled="disabled"
    :custom-request="customRequest"
    :beforeUpload="beforeUploadImg"
    :file-list="form.fileList"
    @change="changeFile"
  >
    <!--    <img v-if="pic" :src="pic" alt="封面" class="cover-img"/>-->
    <div v-if="showAdd">
      <a-icon :type="loading ? 'loading' : 'plus'" />
      <div class="ant-upload-text">{{ text }}</div>
    </div>
  </a-upload>

  <a-upload
    v-else
    :disabled="uploading"
    name="file"
    :accept="accepts"
    :file-list="form.fileList"
    :multiple="multiple"
    :before-upload="beforeUpload"
    :custom-request="handleRequestUpload"
    @change="changeFile"
  >
    <a-button>{{ text }}</a-button>
  </a-upload>
</template>

<script>
import { commonUpload, uploadNameList } from '@/api/config';

export default {
  name: 'uploadFile',
  props: {
    text: {
      type: String,
      default: '上传'
    },
    accept: {
      type: String,
      default: ''
    },
    uploadAction: {
      type: String,
      default: 'commonUpload'
    },
    isImgUpload: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxNum: {
      type: Number,
      default: 1
    },
    infinity: {
      type: Boolean,
      default: false
    },
    localFile: {
      type: Boolean,
      default: false
    },
    uploadVideo: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: 100
    },
    fileLists: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    fileLists: {
      handler(val) {
        if (val && val.length) {
          this.form.fileList = JSON.parse(JSON.stringify(this.fileLists));
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      uploading: false,
      form: {
        fileList: []
      },
      loading: false,
      pic: '',
      commonUpload,
      uploadNameList
    };
  },
  computed: {
    accepts() {
      if (this.accept) {
        return this.accept;
      } else if (this.isImgUpload) {
        return this.uploadVideo ? 'video/*' : '.jpg, .jpeg, .png';
      } else if (this.localFile) {
        return '';
      } else {
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';
      }
    },
    showAdd() {
      return (
        (!this.form.fileList || this.form.fileList.length < this.maxNum) &&
        !this.infinity
      );
    }
  },
  methods: {
    beforeUpload(file) {
      this.form = {
        fileList: [file],
        title: (file?.name || '').replace(/\.xlsx$/g, '')
      };
    },
    // 自定义上传
    async handleRequestUpload(options = {}) {
      const { file, onError, onSuccess } = options;
      if (this.uploading) {
        onError('Uploading', 'Uploading');
        return;
      }
      this.uploading = true;
      const tmpFun = this[this.uploadAction];
      const formData = new FormData();
      formData.append('file', file);
      this.uploadAction === 'commonUpload' &&
        formData.append('source', 'course_task');
      const data = await tmpFun(formData);
      if (!data || data.error_code) {
        this.uploading = false;
        onError(data?.message || '上传失败');
        this.$message.error(data?.message || '上传失败');
        return;
      }
      let num = this.form.fileList.length;
      if (num > this.maxNum || num === this.maxNum) {
        this.form.fileList.splice(0, 1);
      }
      this.form.fileList.push(file);
      // this.form = {...this.form, fileList: [file]};
      this.$emit('success', data.data, this.form.fileList);
      onSuccess(formData);
      this.uploading = false;
    },
    customRequest({ file }) {
      this.uploadPicOvert(file);
    },
    async uploadPicOvert(file) {
      const formData = new FormData();
      formData.append('file', file);
      const data = await this[this.uploadAction](formData);
      if (!data || data.error_code) {
        this.$message.error(data?.message || '上传失败');
        return;
      }
      let pic = data?.data?.url || '';
      this.pic = pic;
      file.url = pic;
      file.file_resource_id = data?.data?.file_resource_id || '';
      let num = this.form.fileList.length;
      if (num > this.maxNum || num === this.maxNum) {
        this.form.fileList.splice(0, 1);
      }
      this.form.fileList.push(file);
      // this.form = {...this.form, fileList: this.form.fileList ? [file, ...this.form.fileList] : [file]};
      this.$emit('success', data.data, this.form.fileList);
    },
    beforeUploadImg(file) {
      const types = this.uploadVideo ? ['video/mp4'] : ['jpg', 'jpeg', 'png'];
      const type = file.type;
      const size = file.size;
      return new Promise((resolve, reject) => {
        if (types.every((t) => !type.includes(t))) {
          this.$message.info('图片仅支持 .jpg .jpeg .png 格式，请重新选择图片');
          return reject('ImageTypeError');
        }

        if (size > this.size * 1024 * 1024) {
          const isVideo = this.uploadVideo ? '视频' : '图片';
          this.$message.error(
            `文件大小限制为${this.size}M以内，请更换${isVideo}`
          );
          return reject('ImageSizeError');
        }
        return resolve();
      });
    },
    changeFile(info) {
      const { fileList, file } = info || {};
      if (file) {
        if (file.status === 'uploading') {
          this.uploading = true;
        }
        if (file.status === 'done') {
          this.uploading = false;
          this.$message.success('文件上传成功');

          this.fileList?.forEach((i) => {
            i.file_resource_id =
              i.file_resource_id || i.response?.data?.file_resource_id;
          });
          // this.change();
          this.$emit('uploadLoainng', this.uploading);
        }
        if (file.status === 'removed') {
          this.uploading = false;
          this.$emit('removed');
        }
        if (file.status === 'error') {
          this.uploading = false;
        }
        this.$emit('uploadLoainng', this.uploading);
      }
      this.form.fileList = [...(fileList || [])];
    }
  }
};
</script>

<style scoped>
.cover-img {
  max-width: 104px;
  max-height: 104px;
}
</style>
