import request from '@/utils/Request'

// 积分行为规则列表
export function getIntegralRuleList(params) {
  return request({
    url: `/integral/admin/rule/index`,
    method: 'get',
    params
  })
}

// 积分规则上下架删除
export function changeIntegralRuleStatus(data) {
  return request({
    url: `/integral/admin/rule/status`,
    method: 'post',
    data
  })
}


// 添加积分规则
export function createIntegralRule(data) {
  return request({
    url: `/integral/admin/rule`,
    method: 'post',
    data
  })
}


// 积分规则详情
export function getIntegralRule(params) {
  return request({
    url: `/integral/admin/rule/detail`,
    method: 'get',
    params
  })
}



// 发现话题列表
export function getFindTopicList(params) {
  return request({
    url: `/integral/admin/rule/find/topics`,
    method: 'get',
    params
  })
}

// 规则设置中培训列表
export function getOfflineTrainingList(params) {
  return request({
    url: `/integral/admin/rule/offlines`,
    method: 'get',
    params
  })
}

// 规则设置中考试列表
export function getExamList(params) {
  return request({
    url: `/integral/admin/rule/testpapers`,
    method: 'get',
    params
  })
}

// 规则设置中任务列表
export function getTaskList(params) {
  return request({
    url: `/integral/admin/rule/learnplans`,
    method: 'get',
    params
  })
}


